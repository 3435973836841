import { createContext } from 'react';
import { ProjectExtended, ProjectReporting } from '../../../../@types/Project';
import { FetchProjectsOptions } from '../../../../api-requests/Projects';

export interface IProjectContext {
    getProjects: (d: any) => void;
    projects: ProjectExtended[];
    totalProjects: number;
    loading: boolean;
    projectReportings: ProjectReporting[];
    totalReportings: number;
    getProjectReportings: (params: FetchProjectsOptions) => void;
    periodicReports: any[];
    periodicReportsTotal: number;
    getPeriodicReports: (params: FetchProjectsOptions) => void;
}

const ProjectContext = createContext<IProjectContext | undefined>(
    undefined,
);

ProjectContext.displayName = 'Project';

export default ProjectContext;
