import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';

import ProjectContext from './ProjectContext';
import { ProjectExtended, ProjectReporting } from '../../../../@types/Project';
import { fetchPeriodicReports, fetchProjects, FetchProjectsOptions, fetchProjectsParticipation } from '../../../../api-requests/Projects';

type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};

export const ProjectProvider: FC<TUserProviderProps> = ({ children }) => {
    const [projects, setProjects] = useState<ProjectExtended[]>([]);
    const [projectReportings, setProjectReportings] = useState<ProjectReporting[]>([]);
    const [loading, setLoading] = useState(false)
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalReportings, setTotalReportings] = useState(0);
    const [periodicReports, setPeriodicReports] = useState<ProjectReporting[]>([]);
    const [periodicReportsTotal, setPeriodicReportsTotal] = useState(0);
    const getProjects = async (params: FetchProjectsOptions) => {
        setLoading(true);
        try {
            const projectResponse = await fetchProjects({ ...params });
            setProjects(projectResponse.Results);
            setTotalProjects(projectResponse.TotalResults);
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setLoading(false);
        }
    }
    const getProjectReportings = async (params: FetchProjectsOptions) => {
        setLoading(true);
        try {
            const projectReportingsResponse = await fetchProjectsParticipation({ ...params });
            setProjectReportings(projectReportingsResponse.Results);
            setTotalReportings(projectReportingsResponse.TotalResults);
        } catch (error) {
            console.error('Error fetching reportings', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const getPeriodicReports = async (params: FetchProjectsOptions) => {
        setLoading(true);
        try {
            const projectResponse = await fetchPeriodicReports({ ...params });
            setPeriodicReports(projectResponse.Results);
            setPeriodicReportsTotal(projectResponse.TotalResults);
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setLoading(false);
        }
    }
    const contextValue = useMemo(
        () => ({
            getProjects,
            getPeriodicReports,
            periodicReports,
            periodicReportsTotal,
            projects,
            totalProjects,
            loading,
            projectReportings,
            getProjectReportings,
            totalReportings,
        }),
        [projects, loading, projectReportings],
    );
    return (
        <ProjectContext.Provider value={contextValue} >
            {children}
        </ProjectContext.Provider>
    );
};
