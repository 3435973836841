import { useEffect, useState } from 'react';
import {
  PieChartOutlined,
  UnorderedListOutlined,
  PrinterOutlined,
  CalendarOutlined,
  WalletOutlined,
  SafetyOutlined,
  CarryOutOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  FieldTimeOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  CarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProjectOutlined,
  BookOutlined,
  ContactsOutlined,
  FormOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Layout, Menu, Typography, Grid } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SidebarLogo } from './SidebarLogo';
import { TPermissions, useAuth } from '../../features/auth';
import CalendarModalForm from '../../features/leave/components/Form/CalendarModalForm';
import { useFeature } from '../../features/feature/FeatureProvider';
import { AppColors } from '../../../shared/colors';
import ReportModal from '../../features/report/ReportModal';
import { initLeaveRequest } from '../../utils/FormValidation/LeaveFormValidation';
import { MyLeaveContext, useLeaveContext } from '../../features/leave/provider/GenericLeaveProvider';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

type Props = {
  collapsed: boolean;
  setCollapsed: (d: boolean) => void;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Sidebar = ({ collapsed, setCollapsed }: Props) => {
  const { xs } = useBreakpoint();
  const userPermissions: TPermissions = useAuth().permissions;
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { allowIf } = useAuth();
  const { showFeature } = useFeature();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [data, setData] = useState(initLeaveRequest);
  const [openReport, setOpenReport] = useState(false);
  const { fetchLeavesFunc } = useLeaveContext(MyLeaveContext)
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname,
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  useEffect(() => {
    // Function to update dimensions
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const menuItems: MenuProps['items'] = [
    { key: '/dashboard', icon: <PieChartOutlined />, label: <Link to="/dashboard">Dashboard</Link> },
    showFeature('LEAVES') ? { key: '/apps/leave/calendar', icon: <CalendarOutlined />, label: <Link to="/apps/leave/calendar">Calendar</Link> } : null,
    showFeature('TIMESHEET') ? {
      key: 'sub1',
      icon: <FieldTimeOutlined />,
      label: 'Timesheets',
      children: [
        allowIf(['Timesheets_Approve']) && {
          key: '/apps/timesheet/print-timesheet',
          icon: <PrinterOutlined />,
          label: <Link to="/apps/timesheet/print-timesheet">Print Timesheet</Link>,
        },
        {
          key: '/apps/timesheet/list',
          icon: <UnorderedListOutlined />,
          label: <Link to="/apps/timesheet/list">Timesheet List</Link>,
        },
        {
          key: '/apps/timesheet/list/wp',
          icon: <UnorderedListOutlined />,
          label: <Link to="/apps/timesheet/list/wp">Timesheet per Work Package</Link>,
        },
      ].filter(Boolean),
    } : null,
    showFeature('PROJECTREPORTING') && allowIf(['Projects_Read']) ? {
      key: 'projects',
      icon: <BookOutlined />,
      label: 'Projects',
      children: [
        showFeature('PROJECTREPORTING') && allowIf(['FinancialReporting_Read']) ? {
          key: '/apps/projects/report/list',
          icon: <ProjectOutlined />,
          label: <Link to="/apps/projects/report/list">Project Participations</Link>
        } : null,
        showFeature('PROJECTREPORTING') && allowIf(['FinancialReporting_Read']) ? {
          key: '/apps/projects/periodic_reports',
          icon: <FormOutlined />,
          label: <Link to="/apps/projects/periodic_reports">Periodic Reports</Link>
        } : null,
        allowIf(['Projects_Read']) && {
          key: '/apps/projects/list',
          icon: <ClockCircleOutlined />,
          label: <Link to="/apps/projects/list">Project List</Link>,
        },
      ].filter(Boolean),
    } : null,
    showFeature('MEETING') && allowIf(['Projects_Read']) ? {
      key: 'projectsevents',
      icon: <ContactsOutlined />,
      label: 'Project Events',
      children: [
        allowIf(['Meetings_Read']) && {
          key: '/apps/projects/meetings/list',
          icon: <HistoryOutlined />,
          label: <Link to="/apps/projects/meetings/list">Meetings</Link>,
        },
        {
          key: '/apps/projects/meetings/trip/list',
          icon: <CarOutlined />,
          label: <Link to="/apps/projects/meetings/trip/list">Trips</Link>,
        },
        allowIf(['Trips_Approve']) && {
          key: '/apps/projects/meetings/trip/approval',
          icon: <SafetyOutlined />,
          label: <Link to="/apps/projects/meetings/trip/approval">Trip Approvals</Link>,
        }
      ].filter(Boolean),
    } : null,

    showFeature('HUMANRESOURCES') ? {
      key: 'human_resources',
      icon: <UserOutlined />,
      label: 'Human Resources',
      children: [
        showFeature('LEAVES') ? {
          key: 'leaves',
          icon: <CarryOutOutlined />,
          label: 'Leaves',
          children: [
            allowIf(['LeaveApprovals_ReadWrite']) && {
              key: '/apps/leave/approval-list',
              icon: <SafetyOutlined />,
              label: <Link to="/apps/leave/approval-list">Leave Approvals</Link>,
            },
            {
              key: '/apps/leave/list',
              icon: <WalletOutlined />,
              label: <Link to="/apps/leave/list">My Leaves</Link>,
            },
            allowIf(['LeaveHistory_ViewAll']) && {
              key: '/apps/leave/history',
              icon: <HistoryOutlined />,
              label: <Link to="/apps/leave/history">Leave History</Link>,
            },
          ].filter(Boolean),
        } : null,
        {
          key: '/apps/human/teams',
          icon: <UsergroupAddOutlined />,
          label: <Link to="/apps/human/teams">Teams</Link>,
        },


      ].filter(Boolean),
    } : null,
  ].filter(Boolean) as MenuProps['items'];


  const items: MenuProps['items'] = [
    showFeature('TIMESHEET') ? {
      key: 'add_timesheet',
      label: <Link to="apps/timesheet/create">Timesheet</Link>,
      icon: <UnorderedListOutlined />,
    } : null,

    showFeature('LEAVES') ? {
      key: 'add_leave',
      icon: <CalendarOutlined />,
      label: (
        <Typography onClick={() => setCreateModalOpen(true)}>Leave</Typography>
      ),
    } : null,

    showFeature('MEETING') ? {
      key: 'add_projectMeeting',
      icon: <ClockCircleOutlined />,
      label: (
        <Link to="apps/projects/meetings/create">
          Meeting
        </Link>
      ),
    } : null,
    {
      key: 'add_trip',
      icon: <CarOutlined />,
      label: (
        <Link to="apps/projects/meetings/trip/create">
          Trip
        </Link>
      ),
    }
  ].filter(Boolean);


  return (
    <Sider
      collapsedWidth={xs ? 0 : 100}
      width={xs ? 250 : 250}
      collapsible
      collapsed={collapsed}
      trigger={!xs && null}
      onCollapse={(value) => { xs ? setCollapsed(!value) : setCollapsed(value) }}
      className="flex flex-col justify-between" // Use flex column for the entire sidebar
      style={{
        position: 'fixed',
        overflowY: 'scroll',
        height: windowDimensions.height,
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
      }}
    >
      {isCreateModalOpen && <CalendarModalForm
        fetchData={fetchLeavesFunc}
        isOpen={isCreateModalOpen}
        setIsOpen={setCreateModalOpen}
        data={data}
        setData={setData}
      />}
      {openReport && <ReportModal
        isOpen={openReport}
        onClose={() => setOpenReport(false)}
      />}
      <div className='flex flex-col justify-between h-full'>
        <div className={`flex flex-row items-center ${collapsed ? 'justify-center mt-8' : 'mt-12'}`}>
          <Dropdown menu={{ items }} placement='bottom'>
            <Button
              type="primary"
              className='w-12 h-12 rounded-full text-xl shadow-lg'
              style={{
                display: xs ? collapsed ? 'none' : 'flex' : 'flex',
                zIndex: 50,
                position: 'fixed',
                top: windowDimensions.height - 100,
                left: collapsed ? 75 : 225,
                gap: 4,
                padding: 24,
                overflow: 'initial',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                background: AppColors.primary,
              }}
              data-testid="addEntryBtn"
            >
              <PlusCircleOutlined size={64} />
            </Button>
          </Dropdown>
          <SidebarLogo setCollapsed={setCollapsed} collapsed={collapsed} />
          {xs && !collapsed && < Button
            className="border-solid border border-gray-200 rounded-lg cursor-pointer shadow-2xl"
            icon={
              <LeftOutlined
                className={`text-gray-400 transform transition-all duration-300 ${collapsed ? 'rotate-180' : 'rotate-0'}`}
                style={{ fontSize: '12px' }}
              />
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              position: 'fixed',
              top: 50,
              left: collapsed ? 80 : 230,
              zIndex: 50,
            }}
          />}
          {!xs && < Button
            className="border-solid border border-gray-200 rounded-lg cursor-pointer shadow-2xl"
            icon={
              <LeftOutlined
                className={`text-gray-400 transform transition-all duration-300 ${collapsed ? 'rotate-180' : 'rotate-0'}`}
                style={{ fontSize: '12px' }}
              />
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              position: 'fixed',
              top: 50,
              left: collapsed ? 80 : 230,
              zIndex: 50,
            }}
          />}
        </div>

        {/* Scrollable content */}
        <div style={{ flexGrow: 1, overflowY: 'scroll', marginTop: 20 }}>
          <Menu
            inlineIndent={10}
            theme="dark"
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
          />
        </div>
        {/* Report button at the bottom */}
        <div className="w-full">
          <Button
            data-testid="reportBtn"
            onClick={() => setOpenReport(true)}
            className="rounded-none mx-auto transform transition-all duration-300"
            style={{
              width: collapsed ? 100 : '100%', // Adjust width based on collapse state
            }}
            icon={<ExclamationCircleOutlined />}
          >
            {collapsed && !xs ? '' : 'Report an issue'}
          </Button>
        </div>
      </div>
    </Sider >

  );
};

export default Sidebar;
